<template>
  <div class="topbar-item">
    <div class="d-flex align-items-center btn-lg px-md-2 w-md-auto">
      <span
        class="text-white opacity-70 font-weight-bold font-size-base d-none d-md-inline mr-1"
      >
        Hi,
      </span>
      <span
        class="text-white opacity-90 font-weight-bolder font-size-base d-none d-md-inline mr-4 user_name"
      >
        {{ user_name }}
      </span>
      <!-- <span
        class="symbol-label text-white font-size-h5 font-weight-bold bg-white-o-30"
      >
        {{ user_name.charAt(0).toUpperCase() }}
      </span> -->
    </div>
    <button
      class="btn btn-light-primary btn-bold btn-sign-out"
      @click="onLogout"
    >
      Sign out
    </button>

    <div
      id="kt_quick_user"
      ref="kt_quick_user"
      class="offcanvas offcanvas-right p-10 d-none"
    >
      <!--begin::Content-->
      <perfect-scrollbar
        class="offcanvas-content pr-5 mr-n5 scroll"
        style="max-height: 90vh; position: relative"
      >
        <!--begin::Header-->
        <div class="d-flex align-items-center mt-5">
          <div class="symbol symbol-100 mr-5">
            <img class="symbol-label" :src="picture" alt="" />
            <i class="symbol-badge bg-success"></i>
          </div>
          <div class="d-flex flex-column">
            <a
              href="#"
              class="font-weight-bold font-size-h5 text-dark-75 text-hover-primary user_name"
            >
              {{ user_name }}
            </a>
            <!-- <div class="text-muted mt-1">Application Developer</div> -->
            <div class="navi mt-2">
              <a href="#" class="navi-item">
                <span class="navi-link p-0 pb-2">
                  <span class="navi-icon mr-1">
                    <!-- <span class="svg-icon svg-icon-lg svg-icon-primary">
                      <inline-svg
                        src="media/svg/icons/Communication/Mail-notification.svg"
                      />
                    </span> -->
                  </span>
                  <!-- <span class="navi-text text-muted text-hover-primary">
                    {{ user_email }}
                  </span> -->
                </span>
              </a>
            </div>
            <button class="btn btn-light-primary btn-bold" @click="onLogout">
              Sign out
            </button>
          </div>
        </div>
        <!--end::Notifications-->
      </perfect-scrollbar>
      <!--end::Content-->
    </div>
  </div>
</template>

<style lang="scss" scoped>
#kt_quick_user {
  overflow: hidden;
}
</style>

<script>
import { LOGOUT } from "@/core/services/store/auth.module";
import KTLayoutQuickUser from "@/assets/js/layout/extended/quick-user.js";
import KTOffcanvas from "@/assets/js/components/offcanvas.js";

export default {
  name: "KTQuickUser",
  data() {
    return {};
  },
  mounted() {
    // Init Quick User Panel
    KTLayoutQuickUser.init(this.$refs["kt_quick_user"]);
  },
  methods: {
    onLogout() {
      this.$store
        .dispatch(LOGOUT)
        .then(() => this.$router.push({ name: "login" }));
    },
    closeOffcanvas() {
      new KTOffcanvas(KTLayoutQuickUser.getElement()).hide();
    },
  },
  computed: {
    picture() {
      return process.env.BASE_URL + "media/users/300_21.jpg";
    },
    user_name() {
      console.log(window?.localStorage.getItem("user"));
      return window?.localStorage.getItem("user") || " ";
    },
    user_email() {
      return this.$store.state.auth?.user?.email || "";
    },
  },
};
</script>
<style scoped>
.btn-sign-out {
  height: 35px;
}

.user_name {
  text-transform: capitalize;
}
</style>
